<template>
  <div>
    <v-container v-if="showReview">
      <div v-for="(item, index) in arrays" :key="index">
        <v-row>
          <v-col sm-12 md-12 lg-12>
            <v-card class="mx-auto my-12" max-width="374">
              <v-card-title class="brandName">{{ item.brandInfo.brandName }}</v-card-title>

              <v-card-text>
                <div>
                  <p class="headerList">
                    In the <span style="color: #1a66ff">{{ item.brandInfo.subCategoryName }}</span> sub
                    category
                  </p>
                  <v-row align="center" class="mx-0">
                    <v-rating :value="item.averageStars" color="amber" dense half-increments readonly large>
                      <template v-slot:item="props">
                        <v-icon :color="
                          props.isFilled
                            ? colorMapper(item.averageStars)
                            : 'grey lighten-1'
                        " size="30">
                          {{ props.isFilled ? "mdi-star-box" : "mdi-star-box" }}
                        </v-icon>
                      </template>
                    </v-rating>

                    <div class="numbers">
                      <span style="color: #454554">{{ item.averageStars }} ({{ item.totalRespondents }} reviews)
                      </span>
                    </div>
                  </v-row>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>


        <v-row>
          <v-expansion-panels>
            <v-expansion-panel v-for="(info, review_i) in item.result " :key="review_i">
              <v-expansion-panel-header>
                <v-row align="center" justify="center">
                  <v-col cols="12">
                    <span class="subtitle-1">{{ info.id }}</span>
                  </v-col>
                  <v-rating :value="info.answer.average" half-increments dense readonly
                    :color="colorMapper(info.answer.average)" class="mb-3">
                  </v-rating>

                  <!-- {{ info.answer.average }} -->
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <!-- <h3> Score {{ info.answer.average }}  </h3> -->
                <div v-for="(item, key, index) in info.answer" :key="index">
                  <v-row v-if="key != 'average'">
                    <v-col cols="1">
                      <p>{{ itemKeyMapper(key) }}</p>
                    </v-col>
                    <v-col cols="9 pt-5">
                      <v-progress-linear :value="item" :color="colorMapperPercentage(key)" height="10"
                        background-color="#f2f2f5">
                      </v-progress-linear>
                    </v-col>
                    <v-col cols="2">
                      <p>{{ item }}%</p>
                    </v-col>
                  </v-row>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-row>
      </div>
      <br>
      <v-col class="d-flex justify-end" sm-12 md-12 lg-12>
        <v-btn color="primary" @click="goBack()">{{ $t('back') }}</v-btn>
      </v-col>
    </v-container>


    <v-container align="center" justify="center" v-if="showErrorMessage">
      <p>{{ $t("ireview_no_data") }}</p>
      <v-row>
        <v-col class="d-flex justify-end" sm-12 md-12 lg-12>
          <v-btn color="primary" @click="goBack()">{{ $t('back') }}</v-btn>
        </v-col>
      </v-row>
    </v-container>

  </div>
</template>

<script>
import { ReviewDataService } from "../../services/DataServiceiReview";

const reviewDataService = new ReviewDataService();

export default {
  data() {
    return {
      showErrorMessage: false,
      showReview: false,
      totalRespondents: 0,
    };
  },

  mounted() {
    this.getSearchData();
  },

  methods: {
    itemKeyMapper(key) {
      if (key == "oneStar") return "1";
      else if (key == "twoStar") return "2";
      else if (key == "threeStar") return "3";
      else if (key == "fourStar") return "4";
      else if (key == "fiveStar") return "5";
      else return key;
    },

    colorMapper(value) {
      if (parseInt(value) == 1) return "#FF3722";
      else if (parseInt(value) == 3) return "#FF8622";
      else if (parseInt(value) == 2) return "#FFCE00";
      else if (parseInt(value) == 4) return "#73CF11";
      else if (parseInt(value) == 5) return "#00B67A";
      else return "#00B67A";
    },

    colorMapperPercentage(value) {
      if (value == 'oneStar') return "#FF3722";
      if (value == 'twoStar') return "#FF8622";
      if (value == 'threeStar') return "#FFCE00";
      if (value == 'fourStar') return "#73CF11";
      if (value == 'fiveStar') return "#00B67A";
      else return "#00B67A";
    },

    getSearchData() {
      this.$route.params.data.country = "Malaysia";
      this.$setLoader();
      reviewDataService.getSearchData(this.$route.params.data).then((res) => {
        this.arrays = res.data;
        if (this.arrays[0].totalRespondents > 0) {
          this.showReview = true;
          this.$clearLoader();
        } else {
          this.showErrorMessage = true;
          this.$clearLoader();
        }

      })
    },

    goBack() {
      this.$router.push({ name: 'iReviewLanding' })
    }


  },
};
</script>

<style scoped>
.brandName {
  font-weight: 700;
}

.numbers {
  font-size: 15px;
}

.headerList {
  font-weight: 400;
  color: black;
}

.customRating {
  background: red;
  padding: 10px;
  border-radius: 10px;
}
</style>